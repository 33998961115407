export const SsoHandler = {
    convertToSSOLink: async (link, userID) => {
        let url = new URL(link);

        const ssoTokenResponse = await fetch(
            process.env.REACT_APP_STW_API_URL + 'sso/get-token',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('stw-app-user-token'),
                },
                method: 'POST'
            })
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                console.error(error);
                return {};
            });

        if (ssoTokenResponse !== {} && ssoTokenResponse.success) {
            url.searchParams.set('user_id', ssoTokenResponse.data.user_id);
            url.searchParams.set('sso_token', ssoTokenResponse.data.sso_token);
        }
        return url;
    },
};
